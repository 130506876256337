<div fxLayout="column" fxLayoutAlign="end center" class="wrap h-100">
  @if (showBudget) {
  <div fxFlex="44" class="top w-100">
    <div fxLayout="column" fxLayoutAlign="end center" class="h-100 budget">
      <div class="h2">budget</div>
      <div class="price pos-rel">
        <input type="number" class="number" [(ngModel)]="budget" min="1">
        <div class="pos-abs pseudo">
          <span class="number">{{budget}}</span>
        </div>
      </div>
    </div>
  </div>
  }
  @else {
  <div fxFlex="44" class="top w-100">
    <div fxLayout="column" fxLayoutAlign="end center" class="h-100">
      <div class="h2">price</div>
      <div class="price">
        <span class="number">{{prService.price | number : '1.0-2'}}</span>
        <span class="currency">$</span>
      </div>
    </div>
  </div>
  }
  <div fxFlex class="w-100 text-center pos-rel">
    @if (showBudget) {
    <p class="text3">Add budget to your current retailer account</p>
    <button mat-raised-button class="second_button thin min-button-width fund" (click)="addBudget()">transfer</button>
    }
    @else {
    <p class="text3">Price above is based on the product details</p>
    <button mat-raised-button class="second_button thin min-button-width fund" (click)="fund()">fund</button>
    <!-- <span class="link1 quote pointer pos-abs text-center w-100" (click)="getQuote()">Get a quote</span> -->
    }
    @if (invalidAddress) {
      <div class="toggle-options pos-abs text-center w-100">
        <p class="warn err-msg">Some of the companey address fields are missing</p>
        @if (isSU) {
          <a mat-raised-button class="second_button slim fund" [routerLink]="['/retailers-management/' + auth.user.id]">fill missing details</a>
        }
        @else {
          <a mat-raised-button class="second_button slim fund" [routerLink]="['/settings/' + auth.user.id]">fill missing details</a>
        }
      </div>
    }
    @else {
      <div class="toggle-options pos-abs text-center w-100">
        <div class="pos-rel w-100">
          <div class="or pos-abs w-100">
            or
          </div>
        </div>
        <button mat-raised-button class="second_button slim fund" (click)="showBudget = !showBudget">
          @if (showBudget) {
          Fund product
          }
          @else {
          Add budget
          }
        </button>
      </div>
    }
    
    <span class="link1 no-ul quote pos-abs text-center w-100">
      Current budget is ${{prService.budget | number }}
    </span>
  </div>
</div>