import { Injectable } from '@angular/core';
import { RestService } from '../communication/rest.service';
import { UtilsService } from './utils.service';
import { IPaddleEventCallback, PaddleTransactionAmountResponse } from './payments';
import { Environments, initializePaddle, Paddle } from '@paddle/paddle-js';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  private _paddleInstance: Paddle;
  private _onCheckout: Function;
  constructor(
    private rest: RestService,
    private utils: UtilsService,
    private auth: AuthService,
  ) {
  }

  get paddle() {
    return this._paddleInstance;
  }

  private set paddle(value: Paddle) {
    this._paddleInstance = value;
  }

  private init() {
    return new Promise((resolve, reject) => {
      if (this._paddleInstance) {
        resolve(null);
        return;
      }
      initializePaddle({
        environment: environment.paddle.environment as Environments,
        token: environment.paddle.token,
        eventCallback: (this.eventCallback as any).bind(this),
      }).then((paddleInstance: Paddle | undefined) => {
        if (paddleInstance) {
          this._paddleInstance = paddleInstance;
          resolve(null);
        } else reject();
      });
    });
  }

  addBudget(rid: number, amount: number): Promise<unknown> {
    return new Promise(async (resolve, reject) => {
      const invalidAddress = 'invalid address';
      await this.init();
      try {
        const t = await this.utils.observableToPromise(this.rest.createPaymentTransaction('POST', { amount }, `?rid=${rid}`)) as PaddleTransactionAmountResponse;
        if (!t.address_id) {
          reject(invalidAddress);
          return;
        }
        this._onCheckout = resolve;
        this.paddle.Checkout.open({
          transactionId: t.id
        });
      }
      catch (e) {
        if (e?.error === 'Retailer does not contain a valid address')
          reject(invalidAddress);
        reject(e);
      }
    });
  }

  private eventCallback(param: IPaddleEventCallback) {
    switch (param.name) {
      case 'checkout.loaded': {
        break;
      }
      case 'checkout.customer.created': {
        break;
      }
      case 'checkout.closed': {
        break;
      }
      case 'checkout.completed': {
        this.auth.refreshUserDate();
        setTimeout(() => {
          this.auth.refreshUserDate();
          setTimeout(() => {
            this.auth.refreshUserDate();
          }, 3000);
        }, 3000);
        if (this._onCheckout) this._onCheckout();
        break;
      }
    }
  }
}
