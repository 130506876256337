import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Retailer, RetailersAccountManager } from 'src/app/retailer/retailer';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RestService } from '../../communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { FileObject } from 'src/app/shared/file';
import { KeyValuePair, StudioType } from '../../shared/enums';
import { EnumsService } from 'src/app/shared/enums.service';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { User } from 'src/app/auth/user';
import { BroadcasterNotification, BroadcasterNotificationType } from 'src/app/communication/broadcaster-notifications';
import { ResumableUploadService } from 'src/app/shared/resumable-upload.service';
import { Subscription } from 'rxjs';
import { IP } from '../../shared/retailer-product-ip/retailer-product-ip.interface';

@Component({
  selector: 'app-retailer-profile',
  templateUrl: './retailer-profile.component.html',
  styleUrls: ['./retailer-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class RetailerProfileComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set retailer(retailer: Retailer) {
    this._retailer = { ...retailer };
  };
  @Output('on-change') onChange: EventEmitter<Retailer>;
  @Output('on-valid') onValid: EventEmitter<boolean>;
  @Output('on-ignore') onIgnore: EventEmitter<boolean>;
  get retailer() {
    return this._retailer;
  }
  public retailerForm: UntypedFormGroup;
  public retailerLogo: any;
  public countries: Array<KeyValuePair>;
  public qaModes: Array<KeyValuePair>;
  public isSU: boolean;
  public allowDownloads: boolean;
  public useWatermark: boolean;
  public useMeshWatermark: boolean;
  public automaticApproval: boolean;
  public ip: IP;
  public copyClientFeedbackToJob: boolean;
  public visibleEmbedded: boolean;
  public maximumGlbSize: number;
  public realtimeImageRenderPrice: number;
  public defaultCategoryPrice: number;
  public defineFileRestrictions: boolean;
  public createDefaults: boolean;
  public useHexaNaming: boolean;
  public showDescription: boolean;
  public autoApprove: boolean;
  public rendersApprovesAndGoLive: boolean;
  public applyDueDateGrace: boolean;
  public externalProvider: boolean;
  public uvLayouts: boolean;
  public autoCreateTextureOffers: boolean;
  public sourceFileMatchApproval: boolean;
  public isArtistContractor: boolean;
  public autoValidateSourceFiles: boolean;
  public retailersAccountManagers: Array<any>;
  public users: Array<User>;
  public accountManagers: Array<RetailersAccountManager>;
  public studioTypes: Array<KeyValuePair>;
  public studio: number;
  public exposeAdjustments: boolean;
  public qaMode: number;
  // public industriesTypes: Array<KeyValuePair>;
  private retailerFormSubscription: Subscription;
  private _retailer: Retailer;
  private static DEFAULT_MAX_GLB_SIZE = 5;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private rest: RestService,
    private utils: UtilsService,
    private enums: EnumsService,
    private roles: RolesHelperService,
    // private gql: GraphqlService,
    private broadcaster: BroadcasterService,
    private resumableUpload: ResumableUploadService
  ) {
    this.onChange = new EventEmitter<Retailer>();
    this.onValid = new EventEmitter<boolean>();
    this.onIgnore = new EventEmitter<boolean>();
    this.defineFileRestrictions = this.roles.doesUserHasPermission('define file restrictions')

    // this.industriesTypes = this.enums.getIndustriesTypes();
    this.countries = this.enums.getCounries();
    this.qaModes = this.enums.getQaModes();
    this.isSU = this.roles.isSuperUserOrObserverLoggedIn();
    this.users = [];
    this.studioTypes = this.enums.toKeyValueArray(StudioType);
  }

  ngOnDestroy(): void {
    this.removeListeners();
  }

  ngOnInit() {
    this.createDefaults = this.retailer.demo_account;
    this.accountManagers = this.utils.deepCopyByValue(this.retailer.retailers_account_managers);
    this.initForm(this.createDefaults);
    this.setForm();
  }

  private addListeners(): void {
    this.removeListeners();
    this.retailerFormSubscription = this.retailerForm.valueChanges.subscribe(() => {

      this.onChange.next(this.retailerForm.value);
      this.onValid.next(this.retailerForm.valid);
    });
  }

  /**
   * Unsubscribe from form valueChanges
   */
  private removeListeners(): void {
    this.retailerFormSubscription?.unsubscribe();
  }

  ngOnChanges(simpleChange: SimpleChanges) {
    if (simpleChange.retailer && this.retailer.maximum_glb_size) {
      this.retailer.maximum_glb_size = this.utils.convertBytesToMb(this.retailer.maximum_glb_size);
    }

    if (this.retailer.maximum_glb_size === null) {
      this.retailer.maximum_glb_size = RetailerProfileComponent.DEFAULT_MAX_GLB_SIZE;
    }

    if (this.retailerForm) {
      this.setForm();
    }
  }

  initForm(isDemoAccount?) {
    let group = {
      id: [null],
      name: [null, Validators.required],
      website: [null, !this.createDefaults ? Validators.required : null],
      allow_downloads: [null],
      use_watermark: [true],
      ip: [IP['Client’s IP']],
      copy_client_feedback_to_job: [null],
      use_mesh_watermark: [null],
      maximum_glb_size: [null],
      demo_account: [false],
      visible_embedded: [true],
      use_hexa_naming: [null],
      show_description: [null],
      auto_internally_approve: [null],
      realtime_image_render_price: [null],
      studio_type: [null],
      default_category_price: [null],
      automatic_approval_for_approved_items: [null],
      renders_auto_approve_go_live: [null],
      auto_create_texture_offers: [null],
      source_file_match_approval: [null],
      is_artist_contractor: [null],
      auto_validate_source_files: [null],
      apply_due_date_grace: [null],
      generate_uv_layouts: [null],
      external_provider: [null],
      expose_adjustments: [null],
      job_qa_mode: [null],
    } as any;
    if (!isDemoAccount) {
      group.address = [null, Validators.required];
      group.industry = null;
      group.logo_url = null;
      group.id = null;
      group.city = [null, Validators.required];
      group.region = [null, Validators.required];
      group.zip = [null, Validators.required];
      group.tax_id = [null, Validators.required];
      group.country_code = [null, Validators.required];
    }
    if (this.retailer?.id) {
      Object.assign(group, this.retailer);
    }
    this.retailerForm = this.formBuilder.group(group);
    this.onValid.next(this.retailerForm.valid);
    this.addListeners();
  }

  private setRetailerLogo(url: string) {
    this.retailerLogo = {
      'background-image': "url('" + url + "')"
    };
  }

  setForm() {
    this.onIgnore.next(true);

    for (let i in this.retailerForm.controls) {
      if (this.retailer[i])
        this.retailerForm.controls[i].setValue(this.retailer[i]);
    }
    this.createDefaults = this.retailerForm.controls.demo_account.value;
    this.allowDownloads = this.retailerForm.controls.allow_downloads.value;
    this.useWatermark = this.retailerForm.controls.use_watermark.value;
    this.useMeshWatermark = this.retailerForm.controls.use_mesh_watermark.value;
    this.copyClientFeedbackToJob = this.retailerForm.controls.copy_client_feedback_to_job.value;
    this.automaticApproval = this.retailerForm.controls.automatic_approval_for_approved_items.value;
    this.ip = this.retailerForm.controls.ip.value;
    this.visibleEmbedded = this.retailerForm.controls.visible_embedded.value;
    this.useHexaNaming = this.retailerForm.controls.use_hexa_naming.value;
    this.showDescription = this.retailerForm.controls.show_description.value;
    this.autoApprove = this.retailerForm.controls.auto_internally_approve.value;
    this.rendersApprovesAndGoLive = this.retailerForm.controls.renders_auto_approve_go_live.value;
    this.autoCreateTextureOffers = this.retailerForm.controls.auto_create_texture_offers.value;
    this.sourceFileMatchApproval = this.retailerForm.controls.source_file_match_approval.value;
    this.isArtistContractor = this.retailerForm.controls.is_artist_contractor.value;
    this.autoValidateSourceFiles = this.retailerForm.controls.auto_validate_source_files.value;
    this.applyDueDateGrace = this.retailerForm.controls.apply_due_date_grace.value;
    this.uvLayouts = this.retailerForm.controls.generate_uv_layouts.value;
    this.externalProvider = this.retailerForm.controls.external_provider.value;
    this.exposeAdjustments = this.retailerForm.controls.expose_adjustments.value;
    this.maximumGlbSize = this.retailerForm.controls.maximum_glb_size.value;
    this.realtimeImageRenderPrice = this.retailerForm.controls.realtime_image_render_price.value;
    this.defaultCategoryPrice = this.retailerForm.controls.default_category_price.value;
    this.studio = this.retailerForm.controls.studio_type.value ?? -1;
    this.qaMode = this.retailerForm.controls.job_qa_mode.value ?? 1;
    this.onIgnore.next(false);
    this.setRetailerLogo(this.retailer.logo_url);
  }

  async onFilesChange(fileList: Array<FileObject>) {
    let file = fileList[0];
    if (!file) return;
    this.onValid.next(false);
    this.setRetailerLogo(file.base64);
    try {
      const url = await this.resumableUpload.file(file.file);
      this.setRetailerLogo(url);
      this.retailerForm.controls.logo_url.setValue(url);
    }
    catch (e) {
      this.utils.httpErrorResponseHandler(e, 'failure uploading image');
    }
    this.onValid.next(true);
  }

  onChangeDownloads(val: boolean) {
    this.retailerForm.controls.allow_downloads.setValue(val);
    this.onChange.next(this.retailerForm.value);
  }

  onChangeWatermark(val: boolean) {
    this.retailerForm.controls.use_watermark.setValue(val);
    this.onChange.next(this.retailerForm.value);
  }

  onVisibleEmbedded(val: boolean) {
    this.retailerForm.controls.visible_embedded.setValue(val);
    this.onChange.next(this.retailerForm.value);
  }

  onChangeMaxGlbSize() {
    this.retailerForm.controls.maximum_glb_size.setValue(this.maximumGlbSize);
    this.onChange.next(this.retailerForm.value);
  }

  onRealtimeImageRenderPrice() {
    this.retailerForm.controls.realtime_image_render_price.setValue(this.realtimeImageRenderPrice);
    this.onChange.next(this.retailerForm.value);
  }

  onDefaultCategoryPrice() {
    this.retailerForm.controls.default_category_price.setValue(this.defaultCategoryPrice);
    this.onChange.next(this.retailerForm.value);
  }

  onStudioType() {
    let studio_type = this.studio === -1 ? null : this.studio;
    this.retailerForm.controls.studio_type.setValue(studio_type);
    this.onChange.next(this.retailerForm.value);
  }

  onChangeDefaults(val: boolean) {
    this.createDefaults = val;
    this.retailer.demo_account = val;
    this.retailerForm.controls.demo_account.setValue(val);
    this.initForm(val);
    this.setForm();
    this.onChange.next(this.retailerForm.value);
  }

  onUseHexaNaming() {
    this.retailerForm.controls.use_hexa_naming.setValue(this.useHexaNaming);
    this.onChange.next(this.retailerForm.value);
  }

  onShowDescription() {
    this.retailerForm.controls.show_description.setValue(this.showDescription);
    this.onChange.next(this.retailerForm.value);
  }

  public onAutoApprove(): void {
    this.retailerForm.controls.auto_internally_approve.setValue(this.autoApprove);
    this.onChange.next(this.retailerForm.value);
  }

  onUseMeshWatermark() {
    this.retailerForm.controls.use_mesh_watermark.setValue(this.useMeshWatermark);
    this.onChange.next(this.retailerForm.value);
  }

  onCopyClientFeedbackToJob() {
    this.retailerForm.controls.copy_client_feedback_to_job.setValue(this.copyClientFeedbackToJob);
    this.onChange.next(this.retailerForm.value);
  }

  onAutomaticApproval() {
    this.retailerForm.controls.automatic_approval_for_approved_items.setValue(this.automaticApproval);
    this.onChange.next(this.retailerForm.value);
  }

  onRendersApprovesAndGoLive() {
    this.retailerForm.controls.renders_auto_approve_go_live.setValue(this.rendersApprovesAndGoLive);
    this.onChange.next(this.retailerForm.value);
  }

  onAutoCreateTextureOffers() {
    this.retailerForm.controls.auto_create_texture_offers.setValue(this.autoCreateTextureOffers);
    this.onChange.next(this.retailerForm.value);
  }

  onSourceFileMatchApproval() {
    this.retailerForm.controls.source_file_match_approval.setValue(this.sourceFileMatchApproval);
    if (this.sourceFileMatchApproval) {
      this.autoValidateSourceFiles = false;
      this.onAutoValidateSourceFiles();
    }
    this.onChange.next(this.retailerForm.value);
  }

  onIsArtistContractor() {
    this.retailerForm.controls.is_artist_contractor.setValue(this.isArtistContractor);
    this.onChange.next(this.retailerForm.value);
  }

  onQaMode() {
    this.retailerForm.controls.job_qa_mode.setValue(this.qaMode);
    this.onChange.next(this.retailerForm.value);
  }

  onAutoValidateSourceFiles() {
    this.retailerForm.controls.auto_validate_source_files.setValue(this.autoValidateSourceFiles);
    if (this.autoValidateSourceFiles) {
      this.sourceFileMatchApproval = false;
      this.onSourceFileMatchApproval();
    }
    this.onChange.next(this.retailerForm.value);
  }

  onApplyDueDateGrace() {
    this.retailerForm.controls.apply_due_date_grace.setValue(this.applyDueDateGrace);
    this.onChange.next(this.retailerForm.value);
  }

  onUvLayouts() {
    this.retailerForm.controls.generate_uv_layouts.setValue(this.uvLayouts);
    this.onChange.next(this.retailerForm.value);
  }

  public onExternalProviderToggle(): void {
    this.retailerForm.controls.external_provider.setValue(this.externalProvider);
    this.onChange.next(this.retailerForm.value);
  }

  public onExposeAdjustmentsToggle(): void {
    this.retailerForm.controls.expose_adjustments.setValue(this.exposeAdjustments);
    this.onChange.next(this.retailerForm.value);
  }

  onIpChange(ip: IP) {
    this.ip = ip;
    this.retailerForm.controls.ip.setValue(this.ip);
    this.onChange.next(this.retailerForm.value);
  }

  deleteImage() {
    delete this.retailerLogo;
    this.retailerForm.controls.logo_url.setValue(null);
  }

  onUserChanged(user: User) {
    if (user) {
      let isUserExists = false;
      this.accountManagers.forEach(
        a => a.users.forEach(u => {
          if (u.id === user.id)
            isUserExists = true;
        })
      );

      if (!isUserExists) {
        this.rest.accountManager('post', { user_id: user.id }, "?rid=" + this.retailer.id).subscribe(
          () => {
            let newaccountManager: RetailersAccountManager = {
              user_id: user.id,
              users: [{
                email: user.email,
                firstname: user.firstname,
                lastname: user.lastname,
                avatar_url: null,
                phone_number: null,
                retailers_users: null,
                subscribed: null,
                subscribed_notifications: null,
                subscribed_daily_analytics: null,
                subscribed_weekly_analytics: null,
                users_mails_subscriptions: null,
                users_mails_subscriptions_intervals: null
              }]
            }
            this.accountManagers.push(newaccountManager);
          },
          err => this.utils.httpErrorResponseHandler(err, 'failure add account manager ')
        );
      } else {
        let data: BroadcasterNotification = {
          text: 'this user already exists',
          type: BroadcasterNotificationType.Info,
          action: 'OK'
        }
        this.broadcaster.broadcast('notifyUser', data);
      }
    }
  }

  removeUser(a: RetailersAccountManager) {
    this.rest.accountManager('DELETE', {}, "/" + a.id + "?rid=" + this.retailer.id).subscribe(
      () => {
        let indx = this.accountManagers.indexOf(a);
        if (indx > -1) {
          this.accountManagers.splice(indx, 1);
        }
      },
      err => this.utils.httpErrorResponseHandler(err, "Can't delete account manager user")
    );
  }

  get retailerKey(): string {
    return this.retailer.retailer_key;
  }
}
