<header class="center-me">
  <div class="wrap" fxLayout="row" fxLayoutAlign="space-around center">
    <div class="logo-wrap" fxFlex.gt-sm="18" fxFlex="13">
      <span class="options float-dir">
        <mat-menu #appMenu="matMenu" [hidden]="auth.isloggedIn()">
          <!-- <a mat-menu-item routerLink="/dashboard">Overview (0$)</a> -->
          <a mat-menu-item routerLink="/products">My Assets ({{productService.getTotalProductSummary() | number}})</a>
          <a mat-menu-item routerLink="/product">Order New Asset</a>
          <a mat-menu-item routerLink="/products-audit">Products History</a>
          <a mat-menu-item routerLink="/roles-management" *ngIf="roles.doesUserHasPermission('edit roles')">Roles and
            Permissions</a>
          <a mat-menu-item routerLink="/scraper" *ngIf="roles.isSuperUserLoggedIn()">Scraper</a>
          <a mat-menu-item routerLink="/scraper-batch" *ngIf="roles.isSuperUserLoggedIn()">Scraper Batch</a>
          <a mat-menu-item routerLink="/users-management" *ngIf="roles.isSuperUserOrObserverLoggedIn()">Users</a>
          <a mat-menu-item routerLink="/retailers-management" *ngIf="roles.isSuperUserOrObserverLoggedIn()">Retailers</a>
          <a mat-menu-item routerLink="/broadcast" *ngIf="roles.isSuperUserOrObserverLoggedIn()">Broadcast</a>
          <a mat-menu-item routerLink="/dashboard" *ngIf="roles.isSuperUserOrObserverLoggedIn()">Dashboard</a>
          <a mat-menu-item routerLink="/terms">Terms of Use</a>
          <a mat-menu-item routerLink="/privacy">Privacy Policy</a>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="appMenu">
          <mat-icon color="accent">menu</mat-icon>
        </button>
      </span>
      <a routerLink="/products" class="hide-sm">
        <!-- <img alt="hexa 3d" class="logo" src="https://cdn.hexa3d.io/hotlink-ok/site/logo_side.png"> -->
        <img alt="hexa 3d" class="logo" src="https://cdn.hexa3d.io/hotlink-ok/icons/logo/hexa_logo_cms.svg">
      </a>
    </div>
    <div class="right-side" fxFlex="82">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex class="h-wrap pos-rel">
          <h1 class="float-dir ellipsis">{{globals.getH1()}}</h1>
          <h2 class="float-dir gray">{{globals.getH2()}}</h2>
          <span class="breadcrumbs pos-abs">
            <span *ngFor="let obj of globals.getBreadcrumbs(); let i = index">
              <a [href]="obj.nativeLink || obj.link" class="link2" (click)="goTo($event, obj)">
                {{obj.text}}
              </a>
              <span class="link2 no-ul" *ngIf="i < globals.getBreadcrumbs().length - 1"> <i class="icon-right va-sub"></i> </span>
            </span>
          </span>
        </div>
        <div fxFlex="120px" fxFlex.gt-sm="" class="text-op-dir" *ngIf="auth.isloggedIn()">
          <app-users-summary class="disp-block float-op-dir"></app-users-summary>
          <!-- <button mat-button (click)="auth.logout()">
            logout
          </button>
          <a routerLink="/settings/{{user.id}}" *ngFor="let user of retailerUsers" mat-mini-fab class="uppercase" color="primary">
            {{getAcronyms(user)}}
          </a> -->
        </div>
      </div>
    </div>
  </div>
</header>
