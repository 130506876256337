<form [formGroup]="retailerForm" novalidate>
  <div fxLayout="column" fxLayoutAlign="space-between center">
    <div fxFill>
      <div class="files-wrap file-image">
        <app-file-upload *ngIf="!retailerLogo" class="accent" [round]="true" [text]="'COMPANY LOGO'" [drop-mode]="true"
          [icon]="'cloud_upload_outline'" [miltiple]="false" (filesChange)="onFilesChange($event)" [accept]="'image/*'">
        </app-file-upload>
        <div *ngIf="retailerLogo" class="logo-wrap">
          <span class="img" [ngStyle]="retailerLogo"></span>
          <!-- <img [src]="retailerLogo" alt="profile image"> -->
          <button class="delete-image" mat-button (click)="deleteImage()">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div fxFill>
      <mat-form-field fxFill>
        <mat-label>Company Name (this name will appear in the receipt)</mat-label>
        <input matInput type="text"
          formControlName="name" required>
        <mat-hint *ngIf="retailerForm.hasError('required',['name']) && retailerForm.dirty" align="start">company name
          is required</mat-hint>
      </mat-form-field>
    </div>
    <div fxFill>
      <mat-form-field fxFill>
        <mat-label>Company Website</mat-label>
        <input matInput type="text" formControlName="website"
          [required]="!createDefaults || null"
          [pattern]="!createDefaults ? '[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}' : '' ">
        <mat-hint *ngIf="!createDefaults && retailerForm.hasError('required',['website']) && retailerForm.dirty"
          align="start">website is
          required</mat-hint>
        <mat-hint *ngIf="!createDefaults && retailerForm.hasError('pattern',['website']) && retailerForm.dirty"
          align="start">valid
          website looks like "my-domain.com" or "my-domain.co.uk"</mat-hint>
      </mat-form-field>
    </div>
    @if (!createDefaults) {
      <div fxFill>
        <mat-form-field fxFill>
          <mat-label>Address</mat-label>
          <input matInput type="text" formControlName="address" required>
          <mat-hint *ngIf="retailerForm.hasError('required',['address']) && retailerForm.dirty" align="start">address is
            required</mat-hint>
        </mat-form-field>
      </div>
      <div fxFill>
        <mat-form-field fxFill>
          <mat-label>City</mat-label>
          <input matInput type="text" formControlName="city" required>
          <mat-hint *ngIf="retailerForm.hasError('required',['city']) && retailerForm.dirty" align="start">city is
            required</mat-hint>
        </mat-form-field>
      </div>
      <div fxFill>
        <mat-form-field fxFill>
          <mat-label>Region / State</mat-label>
          <input matInput type="text" formControlName="region" required>
          <mat-hint *ngIf="retailerForm.hasError('required',['region']) && retailerForm.dirty" align="start">region / state is
            required</mat-hint>
        </mat-form-field>
      </div>
      <div fxFill>
        <mat-form-field fxFill>
          <mat-label>Zip Code</mat-label>
          <input matInput type="text" formControlName="zip" required>
          <mat-hint *ngIf="retailerForm.hasError('required',['zip']) && retailerForm.dirty" align="start">zip code is
            required</mat-hint>
        </mat-form-field>
      </div>
      <div fxFill>
        <mat-form-field fxFill>
          <mat-label>Tax ID</mat-label>
          <input matInput type="text" formControlName="tax_id" required>
          <mat-hint *ngIf="retailerForm.hasError('required',['tax_id']) && retailerForm.dirty" align="start">tax ID is
            required</mat-hint>
        </mat-form-field>
      </div>
      <div fxFill>
        <mat-form-field fxFill>
          <mat-label>Country</mat-label>
          <mat-select formControlName="country_code" required>
            <mat-option *ngFor="let c of countries" [value]="c.key">{{ c.value }}</mat-option>
          </mat-select>
          <mat-hint *ngIf="retailerForm.hasError('required',['country_code']) && retailerForm.dirty" align="start">country
            code is required
          </mat-hint>
        </mat-form-field>
      </div>
    }
    <div fxFill>
      <app-users-autocomplete [placeholder]="'Account Managers'" (user-change)="onUserChanged($event)"
        [show-all]="true">
      </app-users-autocomplete>
      <div *ngIf="this.accountManagers" class="user-list">
        <mat-chip-grid #chipGrid class="customized">
          @for (a of this.accountManagers; let i = $index ; track t) {
            <mat-chip-row (removed)="removeUser(a)">
              @for (u of a.users; track u) {
              <div>
                <span class="">{{u?.email}}&nbsp;</span>
                <span class="">({{u?.firstname}}&nbsp;</span>
                <span class="">{{u?.lastname}})</span>
              </div>
              <button matChipRemove aria-label="'remove ' + u?.firstname">
                <mat-icon>cancel</mat-icon>
              </button>
              }
            </mat-chip-row>
          }
        </mat-chip-grid>
      </div>
    </div>
  </div>
</form>
<div *ngIf="isSU">
  <div>
    define as demo account
    <app-toggle-checkbox (on-change)="onChangeDefaults($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="createDefaults">
    </app-toggle-checkbox>
  </div>
  <div>
    allow assets downloads
    <app-toggle-checkbox (on-change)="onChangeDownloads($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="allowDownloads">
    </app-toggle-checkbox>
  </div>
  <div>
    use watermark
    <app-toggle-checkbox (on-change)="onChangeWatermark($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="useWatermark">
    </app-toggle-checkbox>
  </div>
  <div>
    embedded tab visible
    <app-toggle-checkbox (on-change)="onVisibleEmbedded($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="visibleEmbedded">
    </app-toggle-checkbox>
  </div>
  <div>
    use hexa naming
    <app-toggle-checkbox (on-change)="onUseHexaNaming($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="useHexaNaming">
    </app-toggle-checkbox>
  </div>
  <div>
    show description
    <app-toggle-checkbox (on-change)="onShowDescription($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="showDescription">
    </app-toggle-checkbox>
  </div>
  <div>
    Products will automatically get internally approved
    <app-toggle-checkbox (on-change)="onAutoApprove($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="autoApprove">
    </app-toggle-checkbox>
  </div>
  <div>
    use mesh watermark
    <app-toggle-checkbox (on-change)="onUseMeshWatermark($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="useMeshWatermark"> </app-toggle-checkbox>
  </div>
  <div>
    copy client feedbacks to job
    <app-toggle-checkbox (on-change)="onCopyClientFeedbackToJob($event)" ngDefaultControl [on-val]="true"
      [off-val]="false" [(ngModel)]="copyClientFeedbackToJob"> </app-toggle-checkbox>
  </div>
  <div>
    Automatic Job Approval for approved items
    <app-toggle-checkbox (on-change)="onAutomaticApproval($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="automaticApproval"> </app-toggle-checkbox>
  </div>
  <div>
    delivered renders will be automatically approved and go live
    <app-toggle-checkbox (on-change)="onRendersApprovesAndGoLive($event)" ngDefaultControl [on-val]="true"
      [off-val]="false" [(ngModel)]="rendersApprovesAndGoLive"> </app-toggle-checkbox>
  </div>
  <div>
    apply due date grace (don't round up due date by week)
    <app-toggle-checkbox (on-change)="onApplyDueDateGrace($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="applyDueDateGrace"></app-toggle-checkbox>
  </div>
  <div>
    generate UV layouts
    <app-toggle-checkbox (on-change)="onUvLayouts($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="uvLayouts"></app-toggle-checkbox>
  </div>
  <div>
    enable “external provider”
    <app-toggle-checkbox (on-change)="onExternalProviderToggle()" ngDefaultControl [on-val]="true" [off-val]="false"
                         [(ngModel)]="externalProvider"></app-toggle-checkbox>
  </div>
  <div>
    expose adjustments
    <app-toggle-checkbox (on-change)="onExposeAdjustmentsToggle()" ngDefaultControl [on-val]="true" [off-val]="false"
                         [(ngModel)]="exposeAdjustments"></app-toggle-checkbox>
  </div>
  <br>
  <div>
    <app-retailer-product-ip [ip]="ip" (selectionChange)="onIpChange($event)"></app-retailer-product-ip>
  </div>
  <div>
    automatically create texture offers upon geometry approval
    <app-toggle-checkbox (on-change)="onAutoCreateTextureOffers($event)" ngDefaultControl [on-val]="true"
      [off-val]="false" [(ngModel)]="autoCreateTextureOffers"> </app-toggle-checkbox>
  </div>
  <div>
    Artist contractor mode
    <app-toggle-checkbox (on-change)="onIsArtistContractor($event)" ngDefaultControl [on-val]="true" [off-val]="false"
                         [(ngModel)]="isArtistContractor"> </app-toggle-checkbox>
  </div>
  <div>
    require source files to be approved by SU/QS/FM
    <app-toggle-checkbox (on-change)="onSourceFileMatchApproval($event)" ngDefaultControl [on-val]="true"
      [off-val]="false" [(ngModel)]="sourceFileMatchApproval"> </app-toggle-checkbox>
  </div>
  <div>
    Enforce and auto validate substance source files
    <app-toggle-checkbox (on-change)="onAutoValidateSourceFiles($event)" ngDefaultControl [on-val]="true" [off-val]="false"
      [(ngModel)]="autoValidateSourceFiles"> </app-toggle-checkbox>
  </div>
  <div>
    <mat-form-field fxFill>
      <mat-label>QA mode</mat-label>
      <mat-select [(ngModel)]="qaMode" (selectionChange)="onQaMode()" required>
        <mat-option *ngFor="let mode of qaModes" [value]="mode.key">{{ mode.value }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field fxFill>
      <mat-label>realtime image render price</mat-label>
      <input (change)="onRealtimeImageRenderPrice()" matInput type="number"
        [(ngModel)]="realtimeImageRenderPrice">
    </mat-form-field>
  </div>
</div>
<div>
  <mat-form-field fxFill>
    <mat-label>default category price</mat-label>
    <input (change)="onDefaultCategoryPrice()" matInput type="number"
      [(ngModel)]="defaultCategoryPrice">
  </mat-form-field>
</div>
<div *ngIf="defineFileRestrictions">
  <mat-form-field fxFill>
    <mat-label>max GLB size</mat-label>
    <input (change)="onChangeMaxGlbSize()" matInput type="number"
      [(ngModel)]="maximumGlbSize">
  </mat-form-field>
</div>
<div fxFill>
  <mat-form-field fxFill>
    <mat-label>amazon studio</mat-label>
    <mat-select [(ngModel)]="studio" (selectionChange)="onStudioType()">
      <mat-option *ngFor="let c of studioTypes" [value]="c.key">{{ c.value }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div>
  retailer_key: {{retailerKey}}
</div>
