import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { GraphqlService } from 'src/app/communication/graphql.service';
import { Retailer, RetailerBatch, RetailersContracts, RetailersFilterOptions } from '../retailer';
import { RolesHelperService } from 'src/app/auth/roles-helper.service';
import { RetailerBatchesService } from '../retailer-batches.service';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
    selector: 'app-retailer-batches',
    templateUrl: './retailer-batches.component.html',
    styleUrls: ['./retailer-batches.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetailerBatchesComponent implements OnInit {

    @Input() retailer: Retailer;
    @Input() setselectedbatches: Array<RetailerBatch>;
    @Input() disabled: boolean;
    @Input() isContrastStyle: boolean;
    @Input() required: boolean;
    @Input() dateLimitation: boolean;
    @Input() multiple: boolean = true;
    @Input('add-default') addDefault: boolean;
    @Output('on-select') onSelect: EventEmitter<Array<RetailerBatch>> = new EventEmitter();

    public retailerBatches: Array<RetailerBatch> = [];
    public selectedbatches: Array<RetailerBatch> = [];
    public currentRetailerId;
    batches = [];
    public batchesDropdownPlaceholder: string;

    constructor(
        private gql: GraphqlService,
        private roles: RolesHelperService,
        private retailerBatchesService: RetailerBatchesService,
        private ref: ChangeDetectorRef,
        private utils: UtilsService
    ) {
        this.onSelect = new EventEmitter<Array<RetailerBatch>>();
        this.required = true;
    }

    ngOnInit(): void {
        if (this.retailer && this.currentRetailerId != this.retailer.id) {
            this.load();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.retailer && this.currentRetailerId != this.retailer.id) {
            this.load();
        }

        if (changes.setselectedbatches?.currentValue?.length === 0) {
            this.selectedbatches = [];
        }
    }

    load() {
        this.retailerBatches = [];
        this.selectedbatches = [];
        this.currentRetailerId = this.retailer.id;
        if (this.retailer && (!this.retailer.retailers_batches || this.retailer.retailers_batches.length === 0)) {
            this.getBatchesByRetailer(this.retailer);
        } else if (this.retailer.retailers_batches && this.retailer.retailers_batches.length > 0) {
            this.createBatchView(this.retailer.retailers_batches, this.retailer.retailers_contracts);
            this.batchesDropdownPlaceholder = 'select batches';
        }
    }

    deselectAll() {
        this.selectedbatches = [];
    }

    getBatchesByRetailer(retailer: Retailer) {
        let options = {
            id: [retailer.id]
        } as RetailersFilterOptions;
        this.gql.retailerBatches(options).subscribe(
            obj => {
                let row = this.utils.deepCopyByValue(this.utils.deepCopyByValue(obj.data.allRetailers.rows[0]));

                this.createBatchView(row.retailers_batches, row.retailers_contracts);
                this.batchesDropdownPlaceholder = `Select ${row.name} batch`;
                this.ref.detectChanges();
            }
        );
    }

    private createBatchView(bs: Array<RetailerBatch>, c: Array<RetailersContracts>): void {
        const batches = bs;
        batches.forEach(b => {
            b.displayName = this.retailerBatchesService.getDisplayName(b, c);
            let sb: RetailerBatch = {};
            let isCurrentSelected = false;
            if (this.setselectedbatches?.some(s => s.batch_id === b.id)) {
                sb = this.setselectedbatches.find(s => s.batch_id === b.id);
                if (sb) {
                    sb.name = b.name;
                    sb.displayName = b.displayName;
                    sb.end_at = b.end_at;
                    this.selectedbatches.push(sb);
                    isCurrentSelected = true;
                }
            }
            sb.batch_id = b.id;
            sb.end_at = b.end_at;
            sb.name = b.name;
            sb.displayName = b.displayName;
            if (isCurrentSelected || this.roles.isSuperUserLoggedIn() || (!this.dateLimitation || new Date(sb.end_at) > new Date())) {
                this.retailerBatches.push(sb);
            }
        });
        if (this.addDefault && (!this.setselectedbatches || this.setselectedbatches.length === 0) && this.retailerBatches.length === 1) {
            this.selectedbatches.push(this.retailerBatches[0]);
        }
        if (this.selectedbatches.length > 0) {
            this.onSelect.next(this.selectedbatches);
        }
    }

    setSelected() {
        if(!Array.isArray(this.selectedbatches)){
            this.selectedbatches = [this.selectedbatches];
        }
        this.onSelect.next(this.selectedbatches);
        if(this.selectedbatches[0]?.name)
        this.batchesDropdownPlaceholder = this.selectedbatches[0]?.name;
    }

    compareObjects(o1: any, o2: any): boolean {
        if (Array.isArray(o2) && o2.length) {
            return o1.batch_id === o2[0].batch_id;
        } else if(o2?.batch_id) {
            return o1.batch_id === o2.batch_id;
        }
    }
}
