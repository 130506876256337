import { CanvasPaintState } from "./enums";
import { UtilsService } from "./utils.service";

export interface FormattedDim {
    size: number;
    suffix: string;
}

export interface ElementDimensions {
    width?: number;
    height?: number;
}

export class ApiCallOptions {
    successMessage?: string;
    errorMessage?: string;
    callback?: Function;
    constructor(successMessage = 'item saved successfully', errorMessage = 'failure saving item') {
        this.successMessage = successMessage;
        this.errorMessage = errorMessage;
    }
}

export enum DimensionsUnits {
    INCH = 1,
    CM = 2,
}

export interface CanvasPaintEvent {
    state: CanvasPaintState;
    clickX: number;
    clickY: number;
    isDragging: boolean;
    color: string;
    lineWidth: number;
    // typePerClick: CanvasPaintState;
    text: string;
    isChunk: boolean;
    isDone?: boolean;
}

export interface ValidState {
    state: boolean;
    message: string;
}

export interface ScriptsFetcher {
    loaded: boolean;
    promise: Array<Function>;
    type: ScriptsFetcherType;
}

export enum ScriptsFetcherType {
    QUILL = 1,
    QUILL_CSS = 2,
    TUS = 3,
    HEXA_VIEWER = 4,
}

export interface IHasDates {
    created_at: Date;
    updated_at: Date;
}

export var CORS_TOKEN = 'NgQun95Vma7QVVc34Lqxw7ETNWwmnuBA';
