<div class="wrap pos-rel" [ngClass]="{'has-error': hasError}">
  <!-- -->
  <div class="reject-reason" *ngIf="prService.request.status_id === 8">
    <span class="material-icons">highlight_off</span> <span class="text">
      Rejected: {{(prService.request?.products_reject_reasons[0]) ? prService.request?.products_reject_reasons[0].reason
      : 'unknown reason'}}
    </span>
  </div>
  <div class="inner-wrap pos-rel">
    <div fxLayout="row" fxLayout.sm="column" fxLayoutAlign="space-between start" class="half top-part w-100 scrollbar">
      <div fxFlex="100" fxFlex.gt-sm="66" class="max-h-100">
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <div fxFlex="70%">
            <h4 class="h2">source data url</h4>
            <mat-form-field class="no-padd" fxFill
              [ngClass]="{'ng-invalid mat-form-field-invalid warn-ph': scrapeUrlError && !scrapeUrl.value}">
              <input matInput type="url" name="scrapeUrl"
                [placeholder]="(scrapeUrlError && !scrapeUrl.value) ? 'First insert the product page URL' : ''"
                #scrapeUrl>
              <!-- <mat-hint *ngIf="regForm.hasError('required','firstname') && regForm.dirty" align="start">first name is required</mat-hint> -->
            </mat-form-field>
            <app-retailers-autocomplete *ngIf="prService.canViewAllRetailers && !prService.request.id"
              [placeholder]="'create for other retailer'" [clear-on-select]="false"
              (retailer-change)="onRetailerChange($event)"></app-retailers-autocomplete>
          </div>
          <div fxFlex class="pos-rel">
            <app-round-checkbox *ngIf="roles.isSuperUserOrObserverLoggedIn()" [isSquare]="true" class="use-api pos-abs"
              ngDefaultControl matTooltip="use API" [(ngModel)]="useApi"></app-round-checkbox>
            <button mat-raised-button class="outline thinner min-thin-button-width center-me capitalize scrape-btn"
              (click)="scrape(scrapeUrl.value)">
              scrape
            </button>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between start" class="images-wrap">
          <div fxFlex class="w-100">
            <h4 class="h2">images</h4>
            <div class="products-data scrollbar" [ngClass]="{'disabled': !prService.canEditItems}">

              <app-file-upload class="accent float-dir" [round]="false" [drop-mode]="true"
                *ngIf="prService.canEditItems" [icon]="'cloud_upload_outline'" [miltiple]="true"
                (filesChange)="onFilesChange($event)" [accept]="'image/*'" [width]="103" [height]="103">
              </app-file-upload>

              <div *ngFor="let imageData of prService.request.products_data; let i = index"
                class="image-data float-dir fade-in" [ngClass]="{'pinned': imageData.sort_index == 0}">
                <div class="overlay">
                  <button class="delete-image" mat-icon-button (click)="deleteImage(imageData, i)"
                    *ngIf="prService.canEditItems">
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                  <button class="link-image" mat-icon-button (click)="viewImage(imageData)">
                    <i class="icon-eye material-icons"></i>
                  </button>
                </div>
                <img [src]="imageData.small_image_url || imageData.url" alt="resource" class="center-me vertical-align">
                <span class="pin" *ngIf="prService.canEditItems">
                  <button mat-icon-button class="v-sub" (click)="pin(i)">
                    <i class="material-icons push_pin">push_pin</i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex class="urls-wrap">
        <div class="error" *ngIf="hasError">
          <div class="warn">
            <mat-icon color="warn">error_outline</mat-icon>
            Upload at least one more image!
          </div>
          <div class="text3">
            Please add missing information in order to complete this asset
          </div>
        </div>
        <div class="space-top">
          <h4 class="h2 add-urls pos-rel">
            <mat-icon class="pos-abs"
              matTooltip="if you're using HEXA's embedded code or API and you want to link this product with multiple URL's you can add those here">
              info_outlined</mat-icon>
            <span>additional url's</span>
          </h4>
          <div class="url-list scrollbar">
            <div *ngFor="let obj of prService.request.products_urls" class="pos-rel">
              <i class="pointer pos-abs remove warn icon-close" (click)="removeUrl(obj)"></i>
              <mat-form-field fxFill class="no-padd">
                <input matInput type="url" [(ngModel)]="obj.url" [value]="obj.url">
              </mat-form-field>
            </div>
            <div>
              <div class="add-url float-dir">
                <mat-form-field fxFill class="no-padd">
                  <input matInput type="url" placeholder="Add Url" name="addUrl" #urlToAdd
                    (keypress)="onAddUrlKeypress($event)">
                </mat-form-field>
              </div>
              <button mat-icon-button class="float-op-dir invert" (click)="addUrl(urlToAdd.value)">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-divider class="midd pos-abs w-100 hide-sm"></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between start" class="half w-100 bottm-part scrollbar">
      <div fxFlex="100" class="h100">
        <mat-tab-group class="no-min-w no-underline customized small-text no-marg-first no-icons" [disableRipple]="true"
          [selectedIndex]="prService.selectedTabIndex" (selectedIndexChange)="prService.selectedIndexChange($event)">
          <mat-tab label="GENERAL DETAILS">
            <div class="bottom-inner-tab">
              <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="4" class="flex-wrap-wrap">
                <div fxFlex.gt-sm="29" fxFlex="100">
                  <mat-form-field fxFill class="no-padd">
                    <mat-label>Name</mat-label>
                    <input matInput type="text" name="pName"
                      [(ngModel)]="prService.request.name_org_lang" required>
                    <!-- <mat-hint *ngIf="!prService.request.name" align="start">name is required</mat-hint> -->
                  </mat-form-field>
                </div>
                <div fxFlex.gt-sm="29" fxFlex="100">
                  <mat-form-field fxFill class="no-padd">
                    <mat-label>Serial Number</mat-label>
                    <input matInput type="text" name="prService.request.serial_number"
                      [(ngModel)]="prService.request.products_identifiers[0].serial_number">
                  </mat-form-field>
                </div>
                <div fxFlex.gt-sm="29" fxFlex="100">
                  <app-retailer-batches *ngIf="prService.request.retailers"
                    [multiple]="false"
                    [disabled]="!roles.isSuperUserLoggedIn() && !!prService.request.id" [add-default]="!prService.request.id"
                    (on-select)="setBatches($event)" [setselectedbatches]="prService.request.products_batches"
                    [retailer]="prService.request.retailers[0]" [dateLimitation]="true">
                  </app-retailer-batches>
                </div>
                <div fxFlex.gt-sm="29" fxFlex="100" class="cat-edit"
                  *ngIf="prService.request.retailers && prService.request.retailers[prService.retailerIndex]">
                  <mat-form-field fxFill *ngIf="newCategoryMode" class="pos-rel no-padd">
                    <span class="pointer pos-abs remove warn" (click)="setNewCategoryMode(false)">x</span>
                    <input matInput type="text" placeholder="enter category" name="newCategoryName"
                      (keypress)="onAddCatKeypress($event)" #catToAdd autofocus>
                  </mat-form-field>
                  <mat-form-field fxFill *ngIf="!newCategoryMode" class="no-padd">
                    <mat-label>Category</mat-label>
                    <mat-select [(ngModel)]="prService.request.retailer_category_id"
                      (selectionChange)="onCategoryChange($event)" [disabled]="!prService.canEditItems">
                      <mat-option (click)="setNewCategoryMode(true)">
                        <b>create new category +</b>
                      </mat-option>
                      <mat-option
                        *ngFor="let cat of prService.request.retailers[prService.retailerIndex].retailers_categories"
                        [value]="cat.id">
                        {{cat.category_name_org_lang}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex.gt-sm="29" fxFlex="100" class="cat-edit"
                  *ngIf="prService.request.retailers && prService.request.retailers[prService.retailerIndex]">
                  <mat-form-field fxFill *ngIf="newSubCategoryMode" class="pos-rel no-padd">
                    <span class="pointer pos-abs remove warn" (click)="setNewSubCategoryMode(false)">x</span>
                    <input matInput type="text" placeholder="enter sub-category" name="newSubCategoryName"
                      (keypress)="onAddSubCatKeypress($event)" #subCatToAdd autofocus
                      [disabled]="!prService.request.retailer_category_id && !prService.request.category">
                  </mat-form-field>
                  <mat-form-field fxFill *ngIf="!newSubCategoryMode" class="no-padd">
                    <mat-label>Sub-Category</mat-label>
                    <mat-select [(ngModel)]="prService.request.retailer_sub_category_id"
                      (selectionChange)="onSubCategoryChange($event)"
                      [disabled]="!prService.canEditItems || (!prService.request.retailer_category_id && !prService.request.category)">
                      <mat-option (click)="setNewSubCategoryMode(true)">
                        <b>create new sub-category +</b>
                      </mat-option>
                      <mat-option
                        *ngFor="let cat of prService.request.retailers[prService.retailerIndex].retailers_sub_categories"
                        [value]="cat.id">
                        {{cat.sub_category_name_org_lang}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxFlex.gt-sm="29" fxFlex="100" *ngIf="canSetProductForExternalProvider">
                  <app-round-checkbox [isSquare]="true" text="External provider" ngDefaultControl
                    [disabled]="externalProviderCheckboxDisabled" [(ngModel)]="prService.request.external_provider"
                    (checked)="onExternalProviderCheckboxChange()">
                  </app-round-checkbox>
                  <div class="external-provider-info" [ngClass]="{'faded': externalProviderCheckboxDisabled}">Product
                    assets will be created by an outsourced supplier.</div>
                </div>
                <div fxFlex.gt-sm="29" fxFlex="100">
                  <div *ngIf="!prService.canEditItems" class="no-edit-wrap">
                    <mat-icon class="warn2 float-dir">error_outline</mat-icon>
                    <span class="text3">
                      Some of the fields are disabled since 3D asset creation has already started
                    </span>
                  </div>
                </div>
              </div>

              <!-- <div fxfill class="fx-fill tag-chip-list-wrap chip-list-wrap no-blur-bottom-border">
                <mat-chip-listbox #tagChipList class="customized">
                  <mat-chip-option *ngFor="let t of prService.request.products_tags; let i = index" [removable]="true">
                    {{t.tag}}
                    <i class="icon-close pointer" (click)="removeTag(t, i)"></i>
                  </mat-chip-option>
                  <input placeholder="Tags" class="tags" [matChipInputFor]="tagChipList"
                    (matChipInputTokenEnd)="addTag($event)">
                </mat-chip-listbox>
              </div> -->

              <mat-form-field fxfill class="fx-fill no-bottom-border">
                <mat-label>Tags</mat-label>
                <mat-chip-grid #chipGrid aria-label="Enter tags" class="customized">
                  @for (t of prService.request.products_tags; let i = $index ; track t) {
                    <mat-chip-row (removed)="removeTag(t, i)">
                      {{t.tag}}
                      <button matChipRemove aria-label="'remove ' + t.tag">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip-row>
                  }
                </mat-chip-grid>
                <input placeholder="Add a new tag . . ."
                        #addTagInput
                        (focus)="onTagFocus()"
                        [matChipInputFor]="chipGrid"
                        (matChipInputTokenEnd)="addTag($event)"/>
              </mat-form-field>
            </div>
          </mat-tab>
          <mat-tab label="ADVANCED">
            <div class="bottom-inner-tab">
              <div>
                <div fxfill fxLayoutAlign="space-between start" fxLayout="row" class="">

                  <div fxFlex.gt-sm="68" fxFlex="100">
                    <div fxfill fxLayoutAlign="start" fxLayout="column" class="">
                      <div fxfill *ngIf="prService.enabledResources && prService.enabledResources.length > 0">
                        <div fxfill fxLayoutAlign="space-between start" fxLayout="row">
                          <div fxFlex.gt-sm="20" fxFlex="10" class="device-wrap">
                            Device status
                          </div>
                          <div fxFlex.gt-sm="40" fxFlex="100">
                            <div fxLayout="row" fxLayoutAlign="space-around start">
                              <div fxFlex [ngClass]="{'gray': prService.request.enabled != 1}" class="device-wrap">
                                <i aria-label="enabled on desktop devices" class="icon-screen icon-button-small"></i>
                                <input type="number" min="0" max="100" [(ngModel)]="prService.request.desktop_traffic"
                                  [disabled]="prService.request.enabled != 1">
                                <app-toggle-checkbox color="accent" ngDefaultControl [on-val]="1" [off-val]="0"
                                  [(ngModel)]="prService.request.enabled"></app-toggle-checkbox>
                              </div>
                            </div>
                          </div>
                          <div fxFlex.gt-sm="40" fxFlex="100">
                            <div fxLayout="row" fxLayoutAlign="space-around start">
                              <div fxFlex [ngClass]="{'gray': prService.request.mobile_enabled != 1}"
                                class="device-wrap">
                                <i aria-label="enabled on mobile devices" class="icon-smartphone icon-button-small"></i>
                                <input type="number" min="0" max="100" [(ngModel)]="prService.request.mobile_traffic"
                                  [disabled]="prService.request.mobile_enabled != 1">
                                <app-toggle-checkbox color="primary" ngDefaultControl [on-val]="1" [off-val]="0"
                                  [(ngModel)]="prService.request.mobile_enabled"></app-toggle-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div fxfill fxFlex *ngIf="hasRenderResource">
                        <div fxfill fxLayoutAlign="start start" fxLayout="row">
                          <div fxFlex.gt-sm="19.5" fxFlex="10" class="device-wrap render-live">
                            Render
                          </div>
                          <div fxFlex.gt-sm="33" fxFlex="100">
                            <div fxLayout="row" fxLayoutAlign="space-around start">
                              <div fxFlex class="device-wrap">
                                <span class="va-top">live</span>
                                <app-toggle-checkbox class="margin-left-64" (on-change)="onRenderLiveChange($event)"
                                  color="accent" ngDefaultControl [on-val]="1" [off-val]="0"
                                  [(ngModel)]="renderLive"></app-toggle-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div fxFlex.gt-sm="32" fxFlex="100" class="dimensions-wrap pos-rel">
                    <span class="text3 dim-title">
                      Dimensions
                      <button *ngIf="prService.hasDimModel()" mat-icon-button (click)="prService.showDimModel()"
                        color="accent" matTooltip="View dimensions embedded into the 3D model">
                        <mat-icon>visibility</mat-icon>
                      </button>
                    </span>
                    <span class="dim-units">
                      <mat-form-field fxFill class="no-padd">
                        <mat-select [(ngModel)]="prService.request.units" [disabled]="!prService.canEditItems"
                          (selectionChange)="prService.setDimensionsUnits()">
                          <mat-option *ngFor="let u of allDimUnits" [value]="u.key">
                            {{ u.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </span>
                    <div fxLayout="row" fxLayoutGap="4" fxLayoutAlign="space-around start" class="dim-list">
                      <div fxFlex="30">
                        <div class="text-center">
                          <input type="number" min="0" [(ngModel)]="prService.request.length"
                            [disabled]="!prService.canEditItems">
                        </div>
                        <div class="text3">
                          Length
                          <div
                            *ngIf="prService.enabledResources[prService.currentResourceIndex] && prService.enabledResources[prService.currentResourceIndex].assets_details && prService.enabledResources[prService.currentResourceIndex].assets_details[0] && prService.enabledResources[prService.currentResourceIndex].assets_details[0].length">
                            &nbsp;({{prService.enabledResources[prService.currentResourceIndex].assets_details[0].length
                            |
                            number : '1.0-2'}})
                          </div>
                        </div>
                      </div>
                      <div fxFlex="30">
                        <div class="text-center">
                          <input type="number" min="0" [(ngModel)]="prService.request.width"
                            [disabled]="!prService.canEditItems">
                        </div>
                        <div class="text3">
                          Width
                          <div
                            *ngIf="prService.enabledResources[prService.currentResourceIndex] && prService.enabledResources[prService.currentResourceIndex].assets_details && prService.enabledResources[prService.currentResourceIndex].assets_details[0] && prService.enabledResources[prService.currentResourceIndex].assets_details[0].width">
                            &nbsp;({{prService.enabledResources[prService.currentResourceIndex].assets_details[0].width
                            |
                            number : '1.0-2'}})
                          </div>
                        </div>
                      </div>
                      <div fxFlex="30">
                        <div class="text-center">
                          <input type="number" min="0" [(ngModel)]="prService.request.height"
                            [disabled]="!prService.canEditItems">
                        </div>
                        <div class="text3">
                          Height
                          <div
                            *ngIf="prService.enabledResources[prService.currentResourceIndex] && prService.enabledResources[prService.currentResourceIndex].assets_details && prService.enabledResources[prService.currentResourceIndex].assets_details[0] && prService.enabledResources[prService.currentResourceIndex].assets_details[0].height">
                            &nbsp;({{prService.enabledResources[prService.currentResourceIndex].assets_details[0].height
                            |
                            number : '1.0-2'}})
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div fxLayoutAlign="space-between start" fxLayout="row" class="flex-wrap-wrap">
                  <div fxFlex.gt-sm="62" fxFlex="100">
                    <mat-form-field fxFill class="no-padd">
                      <mat-label>Description</mat-label>
                      <textarea class="scrollbar" matInput
                        [(ngModel)]="prService.request.notes" [disabled]="!prService.canEditItems"></textarea>
                    </mat-form-field>
                  </div>
                  <div fxFlex.gt-sm="29" fxFlex="100" class="resource-enabled text-center"
                    *ngIf="prService.enabledResources && prService.enabledResources[prService.currentResourceIndex]">
                    <span class="gray1 va-top">
                      Resource Enabled
                    </span>
                    <app-toggle-checkbox ngDefaultControl [on-val]="1" [off-val]="0"
                      [(ngModel)]="prService.enabledResources[prService.currentResourceIndex].resource_enabled">
                    </app-toggle-checkbox>
                  </div>
                </div>
                <div class="polygon-specifications">
                  <div *ngFor="let poly of prService.request.products_polygon_specifications; let i = index"
                    class="polygon-specifications-container">

                    <div class="disp-flex space-between">
                      <mat-form-field class="flex-1 no-padd">
                        <mat-label>Variation Name</mat-label>
                        <input matInput [(ngModel)]="poly.variation_name"
                          name="variation_name">
                      </mat-form-field>
                      <button mat-icon-button class="invert add-remove" (click)="prService.removePoly(i)" color="warn"
                        *ngIf="prService.request.products_polygon_specifications.length > 1">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>

                    <div class="polygon-specifications-row">
                      <mat-form-field class="no-padd">
                        <mat-label>Poly Count Type</mat-label>
                        <mat-select [(ngModel)]="poly.poly_type" class="uppercase">
                          <mat-option *ngFor="let t of prService.polyTypes" [value]="t.key" class="uppercase">
                            {{t.value}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="no-padd">
                        <mat-label>Poly Shape Type</mat-label>
                        <mat-select [(ngModel)]="poly.poly_shape_type" class="uppercase">
                          <mat-option *ngFor="let t of prService.polyShapeTypes" [value]="t.key" class="uppercase">
                            {{t.value}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="no-padd">
                        <mat-label>Job type</mat-label>
                        <mat-select [(ngModel)]="poly.job_type">
                          <mat-option [value]="JobType.Geo">
                            Geometry
                          </mat-option>
                          <mat-option [value]="JobType.Text">
                            Texture
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="polygon-specifications-row">
                      <mat-form-field class="no-padd">
                        <mat-label>Serial Number</mat-label>
                        <input matInput [(ngModel)]="poly.serial_number"
                          name="serial_number">
                      </mat-form-field>

                      <mat-form-field class="no-padd">
                        <mat-label>Minimum # of Polygons</mat-label>
                        <input matInput type="number"
                          [(ngModel)]="poly.min_poly_count" name="min_poly_count">
                      </mat-form-field>

                      <mat-form-field class="no-padd">
                        <mat-label>Maximum # of Polygons</mat-label>
                        <input matInput type="number"
                          [(ngModel)]="poly.max_poly_count" name="min_poly_count">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="disp-flex flex-end">
                    <button mat-icon-button class="invert add-remove" (click)="addPoly()">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                  <div #polyScroll></div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="UPLOADS" *ngIf="showUploadsTab">
            <div class="uploads-tab"
              [ngClass]="{'uploads-tab--disabled': isUploadDisabled, 'empty': !prService.externalResources.length}">
              <div class="uploads-wrap scrollbar" [ngClass]="{'has-files': prService.externalResources.length}">
                <div class="uploads-container scrollbar" #uploadsContainer>
                  <app-drop-zone [accept]="uploadsAcceptedFormats" (fileChange)="onExternalUploadFilesChange($event)"
                    [multiple]="true" (fileNotAccepted)="fileNotAccepted()">
                    <div class="big-drop-zone">
                      Drag & drop files here or &nbsp;<span class="link color-link-2">browse</span>
                    </div>
                    <div class="small-drop-zone">
                      + Add Files
                    </div>
                  </app-drop-zone>
                  <app-external-resource-upload-item *ngFor="let resource of prService.externalResources"
                    [resource]="resource" (preview)="onExternalResourcePreview(resource)"
                    (delete)="onExternalResourceDelete(resource)"
                    [counter]="prService.externalResourcesCounter"></app-external-resource-upload-item>
                </div>
              </div>
              <button mat-raised-button class="third_button float-op-dir min-button-width external-uploads-submit"
                [disabled]="submitExternalFilesDisabled" (click)="submitExternalFiles()">Submit files</button>
            </div>
          </mat-tab>
          <mat-tab label="RENDER"
            *ngIf="prService.renderState !== prService.CANNOT_CREATE && prService.request.products_renders_requests && prService.request.products_renders_requests.length">
            <div class="bottom-inner-tab">
              <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="4" class="flex-wrap-wrap">
                <div class="pos-rel" fxFlex.gt-sm="29" fxFlex="100">
                  <mat-form-field class="no-padd" *ngIf="prService.request.products_renders_requests">
                    <mat-label>Render Type</mat-label>
                    <mat-select [multiple]="true" [compareWith]="compareObjects"
                      [(ngModel)]="prService.request.products_renders_requests[prService.renderIndex].products_renders_requests_types"
                      [disabled]="!prService.canEditRenders" required
                      (selectionChange)="prService.setCurrentRenderState()">
                      <mat-option *ngFor="let t of prService.renderTypes" [value]="t">
                        {{t.name}}
                      </mat-option>
                    </mat-select>
                    <mat-hint class="warn" *ngIf="prService.requestRenderDirty && !prService.currentRenderState"
                      align="start">
                      render type is required
                    </mat-hint>
                  </mat-form-field>
                </div>

                <div class="pos-rel" fxFlex.gt-sm="29" fxFlex="100">
                  <mat-form-field class="no-padd">
                    <mat-label>Background Color</mat-label>
                    <input type="text" matInput
                      [(ngModel)]="prService.request.products_renders_requests[prService.renderIndex].background_color"
                      name="theme-color" [disabled]="!prService.canEditRenders">
                  </mat-form-field>
                </div>
                <div class="pos-rel" fxFlex.gt-sm="29" fxFlex="100"
                  *ngIf="prService.currentRenderState == prService.VIDEO_RENDER || prService.currentRenderState == prService.IMAGES_AND_VIDEO_RENDER">
                  <mat-form-field class="no-padd">
                    <mat-label>Duration</mat-label>
                    <input type="number" matInput
                      [(ngModel)]="prService.request.products_renders_requests[prService.renderIndex].duration"
                      [disabled]="!prService.canEditRenders">
                  </mat-form-field>
                </div>
                <div class="pos-rel" fxFlex.gt-sm="29" fxFlex="100">
                  <mat-form-field class="no-padd">
                    <mat-label>Resolution Width</mat-label>
                    <input type="number" matInput
                      [(ngModel)]="prService.request.products_renders_requests[prService.renderIndex].resolution_width"
                      [disabled]="!prService.canEditRenders">
                  </mat-form-field>
                </div>
                <div class="pos-rel" fxFlex.gt-sm="29" fxFlex="100">
                  <mat-form-field class="no-padd">
                    <mat-label>Resolution Height</mat-label>
                    <input type="number" matInput
                      [(ngModel)]="prService.request.products_renders_requests[prService.renderIndex].resolution_height"
                      [disabled]="!prService.canEditRenders">
                  </mat-form-field>
                </div>
                <div class="pos-rel" fxFlex.gt-sm="29" fxFlex="100"
                  *ngIf="prService.currentRenderState == prService.VIDEO_RENDER || prService.currentRenderState == prService.IMAGES_AND_VIDEO_RENDER">
                  <mat-form-field class="no-padd">
                    <mat-label>Frames per Second</mat-label>
                    <input type="number" matInput
                      [(ngModel)]="prService.request.products_renders_requests[prService.renderIndex].frames_per_second"
                      [disabled]="!prService.canEditRenders">
                  </mat-form-field>
                </div>
                <div class="pos-rel" fxFlex.gt-sm="29" fxFlex="100">
                  <mat-form-field class="no-padd">
                    <mat-label>Product Position</mat-label>
                    <mat-select
                      [(ngModel)]="prService.request.products_renders_requests[prService.renderIndex].product_position"
                      [disabled]="!prService.canEditRenders">
                      <mat-option *ngFor="let pos of prService.positions" [value]="pos.key">
                        {{pos.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="pos-rel" fxFlex.gt-sm="29" fxFlex="100">
                  <!-- <span class="gray1">Product Reflection</span> -->
                  <app-round-checkbox [isSquare]="true" [text]="'Product Reflection'"
                    [disabled]="!prService.canEditRenders" ngDefaultControl
                    [(ngModel)]="prService.request.products_renders_requests[prService.renderIndex].product_reflection">
                  </app-round-checkbox>
                </div>
                <div class="pos-rel" fxFlex.gt-sm="29" fxFlex="100">
                  <!-- <span class="gray1">Product Shadow</span> -->
                  <app-round-checkbox [isSquare]="true" [text]="'Product Shadow'" [disabled]="!prService.canEditRenders"
                    ngDefaultControl
                    [(ngModel)]="prService.request.products_renders_requests[prService.renderIndex].product_shadow">
                  </app-round-checkbox>
                </div>
                <div fxFlex.gt-sm="29" fxFlex="100" *ngIf="prService.renderStatus">
                  <div class="c-render secondary uppercase">
                    <span class="material-icons">
                      done
                    </span>
                    {{prService.renderStatus}}
                  </div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="4" class="flex-wrap-wrap"
                *ngIf="prService.currentRenderState == prService.IMAGES_RENDER || prService.currentRenderState == prService.IMAGES_AND_VIDEO_RENDER">
                <ng-container
                  *ngFor="let angle of prService.request.products_renders_requests[prService.renderIndex].products_renders_requests_angles; let i = index">
                  <div class="pos-rel" fxFlex.gt-sm="29" fxFlex="100">
                    <mat-form-field class="no-padd">
                      <mat-label>X Axis Angle</mat-label>
                      <input type="number" matInput [(ngModel)]="angle.angle_x"
                        [disabled]="!prService.canEditRenders">
                    </mat-form-field>
                  </div>
                  <div class="pos-rel" fxFlex.gt-sm="29" fxFlex="100">
                    <mat-form-field class="no-padd">
                      <mat-label>Y Axis Angle</mat-label>
                      <input type="number" matInput [(ngModel)]="angle.angle_y"
                        [disabled]="!prService.canEditRenders">
                    </mat-form-field>
                  </div>
                  <div class="pos-rel" fxFlex.gt-sm="29" fxFlex="100">
                    <img [src]="angle.screenshot" *ngIf="angle.screenshot" alt="screenshot" class="screenshot"
                      matTooltip="angle screenshot">
                    <button mat-icon-button class="float-op-dir invert add-remove" (click)="prService.removeAngle(i)"
                      color="warn"
                      *ngIf="prService.request.products_renders_requests[prService.renderIndex].products_renders_requests_angles.length > 1">
                      <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button class="float-op-dir invert add-remove" (click)="addAngle()"
                      *ngIf="i == prService.request.products_renders_requests[prService.renderIndex].products_renders_requests_angles.length - 1">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                </ng-container>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="4">
                <div fxFlex.gt-sm="29" fxFlex="100">
                  <div *ngIf="!prService.canEditRenders" class="no-edit-wrap">
                    <mat-icon class="warn2 float-dir">error_outline</mat-icon>
                    <span class="text3">
                      Some of the fields are disabled since rendering process has already started
                    </span>
                  </div>
                </div>
                <div fxFlex.gt-sm="29" fxFlex="100"
                  *ngIf="!prService.request.products_renders_requests[prService.renderIndex]?.id && prService.currentRenderState && prService.request.id">
                  <button mat-raised-button class="third_button min-button-width render-btn"
                    (click)="prService.requestRender()">
                    request render
                  </button>
                  <div
                    *ngIf="!prService.request.products_renders_requests[prService.renderIndex].id && prService.currentRenderState && prService.request.id"
                    class="gray">
                    All RENDER section fields will be saved only after clicking the REQUEST RENDER button above
                  </div>
                </div>
              </div>
              <div #renderBtn></div>
            </div>
          </mat-tab>
          <mat-tab label="SHOWCASE"
            *ngIf="prService.enabledResources && prService.enabledResources.length > 0 && prService.enabledResources[prService.currentResourceIndex].resource_type == 2">
            <div [ngClass]="{'bottom-inner-tab': prService.enabledResources.length < 2}">
              <mat-tab-group class="no-min-w no-underline customized small-text uppercase"
                (selectedIndexChange)="prService.currentResourceIndex = $event"
                [selectedIndex]="prService.currentResourceIndex" *ngIf="prService.enabledResources.length > 1"
                [disableRipple]="true">
                <mat-tab *ngFor="let r of prService.enabledResources; let i = index" label="resource {{i + 1}}">
                </mat-tab>
              </mat-tab-group>
              <app-product-showcase class="disp-block"
                [ngClass]="{'bottom-inner-tab': prService.enabledResources.length > 1}"
                (on-change)="onResourceChange($event)"
                [resource]="prService.enabledResources[prService.currentResourceIndex]" (on-gifgen)="onGifgen()"
                [retailer-id]="prService.request?.retailers[prService.retailerIndex].id">
              </app-product-showcase>
            </div>
          </mat-tab>
          <mat-tab label="EMBEDDED"
            *ngIf="prService.enabledResources.length > 0 && (roles.isSuperUserOrObserverLoggedIn() || prService.request.retailers[prService.retailerIndex].visible_embedded)">
            <div class="bottom-inner-tab">
              <app-resource-embedded [resource]="prService.enabledResources[prService.currentResourceIndex]"
                (on-change)="onEmbeddedChange($event)"></app-resource-embedded>
            </div>
          </mat-tab>
          <mat-tab label="DOWNLOADS"
            *ngIf="!roles.isObserverLoggedIn() && (roles.isSuperUserLoggedIn() || prService.allowDownloads) && prService.enabledResources[prService.currentResourceIndex] && prService.enabledResources[prService.currentResourceIndex].resource_default">
            <div class="space-top space-bottom text-center flex-wrap-wrap disp-flex space-between flex-gap-24 min-h-47">
              <div class="flex-1">
                <app-resource-dropdown [resources]="prService.enabledResources" [multiple]="true"
                  (selected)="onDownloadResourcesChange($event)"></app-resource-dropdown>
              </div>
              <div class="flex-1">
                <mat-form-field class="w-100 no-padd">
                  <mat-label>File Format</mat-label>
                  <mat-select [(ngModel)]="formatToDownloads" [multiple]="true"
                    [disabled]="!isResourcesToDownloadValid()">
                    <mat-option [value]="null" *ngIf="!prService.isCovertSupported">
                      {{prService.enabledResources[prService.currentResourceIndex].resource_type == productService.IMAGE
                      ? 'Download Image' :
                      prService.enabledResources[prService.currentResourceIndex].resource_type == productService.VIDEO ?
                      'Download Video' :
                      'Default File'}}
                    </mat-option>
                    <mat-option [value]="productService.glTF" *ngIf="prService.isCovertSupported">
                      glTF File
                    </mat-option>
                    <mat-option [value]="productService.GLB" *ngIf="prService.isCovertSupported">
                      GLB File
                    </mat-option>
                    <!-- <mat-option [value]="productService.DAE" *ngIf="prService.isCovertSupported && prService.enabledResources[prService.currentResourceIndex].resource_type === productService.DAE">
                      DAE File
                    </mat-option> -->
                    <mat-option [value]="productService.USDZ" *ngIf="prService.isCovertSupported">
                      USDZ File
                    </mat-option>
                    <mat-option [value]="productService.OBJ" *ngIf="prService.isCovertSupported">
                      OBJ File
                    </mat-option>
                    <mat-option [value]="productService.FBX" *ngIf="prService.isCovertSupported">
                      FBX File
                    </mat-option>
                    <mat-option [value]="productService.VIEWER_JSON" *ngIf="prService.isCovertSupported">
                      Scene JSON
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex-1">
                <mat-form-field class="w-100 no-padd">
                  <mat-label>Select Images Format</mat-label>
                  <mat-select [(ngModel)]="imagesFormatToDownloads" [multiple]="true"
                    [disabled]="isImageFormatDisabled || !isResourcesToDownloadValid()">
                    <mat-option [value]="f.key" *ngFor="let f of allImagesFormat">
                      {{f.value}}
                    </mat-option>
                  </mat-select>
                  <mat-hint>for FBX and OBJ</mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="disp-flex flex-end download-wrap">
              <button mat-raised-button class="third_button" [disabled]="!isResourcesToDownloadValid()"
                (click)="downloadResource()">
                download
              </button>
            </div>
          </mat-tab>
          <mat-tab label="FILES">
            <div class="bottom-inner-tab">
              <app-products-attachments [attachments]="prService.request.products_attachments" class="disp-block"
                (on-change)="onAttachmentsCHange($event)"></app-products-attachments>
            </div>
          </mat-tab>
          <mat-tab label="IDENTIFIERS">
            <app-product-identifiers></app-product-identifiers>
          </mat-tab>
          <mat-tab label="DUPLICATE" *ngIf="showDuplicate">
            <div class="bottom-inner-tab">
              <div class="space-bottom text3">
                You can copy this product by selecting one of your other retailer accounts
              </div>
              <app-duplicate-product-external [product]="prService.request">
              </app-duplicate-product-external>
            </div>
          </mat-tab>
          <mat-tab label="SUPER USER" *ngIf="roles.isSuperUserLoggedIn()">
            <div class="bottom-inner-tab">
              <app-duplicate-product [product-id]="prService.request.id" [resources]="prService.enabledResources">
              </app-duplicate-product>
            </div>
            <div *ngIf="prService.finalGlbSize">
              final GLB Size: {{prService.finalGlbSize | number : '1.0-1'}} MiB
            </div>
            <div *ngIf="prService.totalTexturesSize">
              total textures: {{prService.totalTexturesSize | number : '1.0-1'}} MiB
            </div>
            <div *ngIf="jobId" class="last-relevant-job">
              <div class="pointer" (click)="goToTheJob(jobId)">
                <mat-icon>open_in_new</mat-icon> The Last relevant job ({{jobTypeName}})
              </div>
            </div>
            <div *ngIf="!isDummy && prService.request.source_pid">
              <button class="third_button outline min-button-width"
              (click)="createDummyOffer()">
              create dummy offer/job
              </button>
            </div>
            <div *ngIf="isDummy" class="last-relevant-job">
              <mat-icon>info</mat-icon>  A dummy job was created
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center">
              <div>
                <p class="text1">
                  <mat-icon>cloud_download</mat-icon>
                  <span class="v-a-s">allow downloads</span>
                  <app-toggle-checkbox ngDefaultControl [on-val]="true" [off-val]="false"
                    [(ngModel)]="prService.request.allow_downloads"></app-toggle-checkbox>
                </p>
              </div>
              <div>
                <p class="text1">
                  <i aria-label="visible to retailer" class="icon-eye icon-button-small"
                    [ngClass]="{'gray': prService.request.visible != 1}"></i>
                  <span class="v-a-s"> visible to retailer </span>
                  <app-toggle-checkbox ngDefaultControl [on-val]="1" [off-val]="0"
                    [(ngModel)]="prService.request.visible"></app-toggle-checkbox>
                </p>
              </div>
              <div>
                <p class="text1">
                  <span class="v-a-s"> demo </span>
                  <app-toggle-checkbox ngDefaultControl [on-val]="true" [off-val]="false"
                    [(ngModel)]="prService.request.is_poc"></app-toggle-checkbox>
                </p>
              </div>
              <div>
                <p class="text1">
                  <span class="v-a-s"> render only </span>
                  <app-toggle-checkbox ngDefaultControl [strong-val-check]="true" [on-val]="1" [off-val]="null"
                    [(ngModel)]="prService.request.process_type"></app-toggle-checkbox>
                </p>
              </div>
              <div>
                <p class="text1">
                  <span class="v-a-s"> Auto texture offer </span>
                  <app-toggle-checkbox ngDefaultControl [on-val]="true" [off-val]="false"
                    [(ngModel)]="prService.request.auto_create_texture_offers"></app-toggle-checkbox>
                </p>
              </div>
            </div>
            <div class="upload-to-amazon" *ngIf="prService.request?.retailers[0]?.studio_type">
              <div *ngIf="prService.enabledResources[prService.currentResourceIndex]?.id">
                <button mat-raised-button class="third_button"
                        (click)="prService.uploadToClientStorage(prService.enabledResources[prService.currentResourceIndex]?.id)"
                        [disabled]="!hasIdentifierTypePoid">
                  upload resource to Amazon S3
                </button>
                <div class="upload-text warn" *ngIf="!hasIdentifierTypePoid">
                  POID is missing, resources can’t be sent to S3
                </div>
                <div class="upload-text"
                     *ngIf="prService.uploadedToClientStorage || prService.enabledResources[prService.currentResourceIndex]?.uploaded_to_client_storage">
                  resource was uploaded to Amazon S3
                </div>
              </div>
              <br>
              <div *ngIf="prService.enabledResources[prService.currentResourceIndex]?.products_resources_source_files[0]?.id">
                <button mat-raised-button class="third_button"
                        (click)="prService.uploadSourceFileToClientStorage(prService.enabledResources[prService.currentResourceIndex]?.id)"
                        [disabled]="!hasIdentifierTypePoid">
                  upload source file to Amazon S3
                </button>
                <div class="upload-text warn" *ngIf="!hasIdentifierTypePoid">
                  POID is missing, source file can’t be sent to S3
                </div>
                <div class="upload-text"
                     *ngIf="prService.uploadedSourceFileToClientStorage || prService.enabledResources[prService.currentResourceIndex]?.products_resources_source_files[0]?.uploaded_to_client_storage">
                  source file was uploaded to Amazon S3
                </div>
              </div>
            </div>
            <div>
              <mat-form-field appearance="fill" class="no-padd">
                <mat-label>due-date (M/D/Y)</mat-label>
                <input matInput [matDatepicker]="picker"
                  [ngModel]="prService.request.status_id === 8 ? null : prService.request.due_date" (ngModelChange)="prService.request.status_id === 8 ? null : prService.request.due_date = $event" disabled="true">
                <mat-datepicker-toggle [disabled]="true" matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="true"></mat-datepicker>
              </mat-form-field>
              <div class="due-date-reason" *ngIf="prService.request.status_id === 8">
                due date is only for active requests
              </div>
            </div>
            <div>
              <button mat-button (click)="prService.setResourceIndexEnabled(prService.currentResourceIndex, true)"
                *ngIf="prService.request?.products_resources?.length > 1">
                set current ({{prService.currentResourceIndex + 1}}th) resource as default
              </button>
            </div>
            <div>
              <span class="internally-approved v-a-s">internally approved</span>
              <app-toggle-checkbox ngDefaultControl [on-val]="true" [off-val]="false"
                [(ngModel)]="prService.request.ia"></app-toggle-checkbox>
            </div>
            <div *ngIf="canSetProductForExternalProvider">
              <span class="internally-approved v-a-s">External Provider</span>
              <app-toggle-checkbox ngDefaultControl [on-val]="true" [off-val]="false" (on-change)="onExternalProviderCheckboxChange()"
                 [(ngModel)]="prService.request.external_provider"></app-toggle-checkbox>
            </div>
            <div>
              <span class="v-a-s">dynamic priority</span>
              <app-toggle-checkbox ngDefaultControl [on-val]="2" [off-val]="0"
                [(ngModel)]="prService.request.priority_type"></app-toggle-checkbox>
            </div>
            <div>
              <span>
                <mat-form-field class="no-padd">
                  <mat-label>Priority</mat-label>
                  <mat-select [disabled]="prService.request.priority_type === 2"
                    [(ngModel)]="prService.request.priority">
                    <mat-option *ngFor="let p of priorities" [value]="p.key">
                      {{p.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
            <div>
              <span>
                <mat-form-field class="no-padd">
                  <mat-label>Change Status</mat-label>
                  <mat-select
                    [(ngModel)]="prService.request.force_status_id">
                    <mat-option *ngFor="let s of status" [value]="s.key">
                      {{s.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
            <div *ngIf="canSetProductForExternalProvider">
              <span class="internally-approved v-a-s">External Provider</span>
              <app-toggle-checkbox ngDefaultControl [on-val]="true" [off-val]="false" (on-change)="onExternalProviderCheckboxChange()"
                 [(ngModel)]="prService.request.external_provider"></app-toggle-checkbox>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4" class="flex-wrap-wrap">
              <app-retailer-product-ip [ip]="prService.request.ip"
                (selectionChange)="onIpChange($event)"></app-retailer-product-ip>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4" class="flex-wrap-wrap">
              <div fxFlex.gt-sm="15" fxFlex="50">
                <mat-form-field fxFill class="no-padd">
                  <mat-label>Retailer Price</mat-label>
                  <input matInput min="0" type="number" name="price"
                    [(ngModel)]="prService.request.price" [disabled]="!prService.canEditItems">
                </mat-form-field>
              </div>
              <div fxFlex.gt-sm="15" fxFlex="50" class="pos-rel">
                <mat-form-field fxFill class="no-padd">
                  <mat-label>Currency</mat-label>
                  <input matInput type="text" name="prService.request.currency"
                    [(ngModel)]="prService.request.currency" [disabled]="!prService.canEditItems">
                </mat-form-field>
              </div>

            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
  <div class="actions">
    <button mat-raised-button class="prime_button float-op-dir min-button-width" (click)="save()">
      {{prService.request.id ? 'save settings' : 'pay and create'}}
    </button>
    <button mat-raised-button class="second_button outline float-dir min-button-width" (click)="delete()"
      *ngIf="prService.request.id && !prService.request.is_archived">
      delete
    </button>
    <button mat-raised-button class="second_button outline float-dir min-button-width margin-left-15"
      (click)="approveRender()"
      *ngIf="prService?.request?.products_renders_requests[prService.renderIndex]?.status_id=== renderDelivered">
      approve render
    </button>
    <button mat-raised-button class="second_button outline float-dir min-button-width" (click)="onClear()"
      *ngIf="!prService.request.id">
      clear
    </button>
    <button mat-raised-button class="second_button outline float-dir min-button-width approve" (click)="approve()"
      *ngIf="prService.request.status_id == 4 && prService.request.process_type !== 1">
      approve
    </button>
    <ng-container *ngIf="prService.request.status_id == 8">
      <button mat-raised-button class="second_button outline float-dir min-button-width reject" (click)="fixRejects()">
        reject fixed
      </button>
    </ng-container>
    <button mat-raised-button class="second_button outline float-dir min-button-width" (click)="restore()"
      *ngIf="prService.request.id && prService.request.is_archived">
      restore
    </button>
  </div>
</div>